<template>
    <div>
        <template v-if="success">Success</template>
        <template v-else-if="saving">
            <div class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green" />
                <p>Saving registration...</p>
            </div>
        </template>
        <template v-else-if="loading">
            <div class="flex items-center space-x-5">
                <Loader class="w-10 h-10 text-green" />
                <p>Loading registration...</p>
            </div>
        </template>
        <template v-else-if="registration && !loading">
            <div class="">
                <div class="flex justify-between">
                    <h2>Edit Registration #{{ registration.id }}</h2>
                    <div>
                        <button class="btn gray small mr-4" @click="cancel">Cancel</button>
                        <button class="btn small mr-4" :disabled="!canUpdateRegistration"
                            @click="updateRegistration">Save
                            Changes</button>
                    </div>
                </div>

                <!-- emails -->
                <div>
                    <Checkbox v-model="sendEmails" :label="`Resend Emails`" />
                    <p><span class="label">To:</span> <span class="lowercase">{{ toEmail }}</span></p>
                    <p><span class="label">Bcc:</span> <span class="lowercase">{{ bccEmail }}</span></p>
                </div>

                <!-- prices & refund -->
                <div class="flex">
                    <div v-if="order">
                        <template v-if="loadingTotals">
                            <Loader class="w-10 h-10 text-green" />
                        </template>
                        <template v-else-if="selectedTotals && !loadingTotals">
                            <!-- prices -->
                            <div>
                                <p><span class="text-xs text-gray-400">Subtotal:</span> {{ selectedTotals.subtotal |
                                    currency }}</p>
                                <p><span class="text-xs text-gray-400">Discounts:</span> {{ selectedTotals.discounts |
                                    currency }}</p>
                                <p><span class="text-xs text-gray-400">Taxes:</span> {{ selectedTotals.taxes | currency
                                    }}
                                </p>
                                <p><span class="text-xs text-gray-400">Total:</span> {{ selectedTotals.total | currency
                                    }}
                                </p>
                                <p><span class="text-xs text-gray-400">Amount Due:</span> {{ selectedTotals.amount_due |
                                    currency }}</p>
                            </div>

                            <!-- refund/invoice -->
                            <div v-if="isRefund">
                                <div v-if="selectedPayment">
                                    <p>A refund will be issued.</p>
                                    <p>Payment ID: {{ selectedPayment.id }}</p>
                                    <p>Refund Amount: {{ refundAmount }}</p>
                                </div>
                                <div class="mb-4" v-else-if="!selectedPayment">
                                    <p>Please select the payment for the refund.</p>
                                    <div class="flex space-x-2 items-center mb-2">
                                        <h4><i class="fas fa-fw fa-credit-card mr-2"></i>Payments</h4>
                                        <p v-if="order.payments" class="text-sm font-semibold rounded-2xl bg-mint px-3">
                                            {{
                                                order.payments.length }}</p>
                                    </div>
                                    <div class="flex">
                                        <PaymentsListView v-if="order.payments && order.payments.length"
                                            :payments="order.payments" :totals="totals.order"
                                            @select="handlePaymentSelected" />
                                        <Checkbox class="ml-4" v-model="overrideRefund"
                                            :label="'Override Payment Requirement'" />
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="isInvoice">
                                <Checkbox v-model="sendInvoice" :label="`Send Invoice`" />
                                <div></div>
                            </div>
                        </template>
                    </div>
                </div>

                <!-- errors -->
                <div>
                    <p v-if="error" class="text-red">{{ error.message }}</p>
                </div>

                <!-- accordions -->
                <div class="border border-gray-200 rounded-md" v-if="classEvent">
                    <!-- exchange class -->
                    <Accordion class="border-b border-gray-200">
                        <template v-slot:title>
                            <div class="flex justify-between items-center">
                                <h3 class="mr-8">Class</h3>
                                <template v-if="classReview">
                                    <p v-if="classReview" class="badge pink">Review Required</p>
                                    <Checkbox class="ml-4" v-model="override" :label="'Override'" />
                                </template>
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="py-2 px-4 border-t border-gray-200">
                                <Exchange :registration="registration" @override="handleOverride" />
                            </div>
                        </template>
                    </Accordion>

                    <!-- portion changes -->
                    <Accordion class="border-b border-gray-200">
                        <template v-slot:title>
                            <div class="flex justify-between items-center">
                                <h3 class="mr-8">Portions</h3>
                                <p v-if="portionReview" class="badge pink">Review Required</p>
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="py-2 px-4 border-t border-gray-200">
                                <Portions />
                            </div>
                        </template>
                    </Accordion>

                    <!-- selection changes -->
                    <Accordion class="border-b border-gray-200">
                        <template v-slot:title>
                            <div class="flex justify-between items-center">
                                <h3 class="mr-8">Meal Selections</h3>
                                <p v-if="selectionReview || classEventChanged || !registration.selections_confirmed"
                                    class="badge pink">Review Required</p>
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="py-2 px-4 border-t border-gray-200">
                                <Selections :registration="registration" :meals="meals"
                                    :portionQuantity="portion.quantity.value"
                                    @isValid="(value) => { isValid.selections = value }" />
                            </div>
                        </template>
                    </Accordion>

                    <!-- additional instructions --->
                    <Accordion class="border-b last:border-b-0 border-gray-200">
                        <template v-slot:title>
                            <div class="flex justify-between items-center">
                                <h3 class="mr-8">Instructions</h3>
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="py-2 px-4 border-t border-gray-200">
                                <Instructions :instructions="instructions" />
                            </div>
                        </template>
                    </Accordion>

                    <!-- contact information -->
                    <!-- TODO: change this to guest contact info, update guest -->
                    <!-- TODO: add contact update to the order edit page -->
                    <!--                    <Accordion class="border-b last:border-b-0 border-gray-200">-->
                    <!--                        <template v-slot:title>-->
                    <!--                            <div class="flex justify-between items-center">-->
                    <!--                                <h3 class="mr-8">Contact</h3>-->
                    <!--                                <p v-if="contactReview" class="badge pink">Review Required</p>-->
                    <!--                            </div>-->
                    <!--                        </template>-->
                    <!--                        <template v-slot:content>-->
                    <!--                            <div class="py-2 px-4 border-t border-gray-200">-->
                    <!--                                <ContactInfo :contact="contactInfo"/>-->
                    <!--                            </div>-->
                    <!--                        </template>-->
                    <!--                    </Accordion>-->

                    <!-- delivery address --->
                    <!-- TODO: only show this when the class has changed and add this to the order edit page -->
                    <template
                        v-if="classEvent.fulfillment.key === 'delivery' || classEvent.fulfillment.key === 'shipping'">
                        <Accordion>
                            <template v-slot:title>
                                <div class="flex justify-between items-center">
                                    <h3 class="mr-8">Shipping</h3>
                                    <p v-if="shippingReview" class="badge pink">Review Required</p>
                                    <p v-if="!isValid.shipping" class="badge pink">Invalid Address</p>
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="py-2 px-4 border-t border-gray-200">
                                    <ShippingAddress :address="shippingAddress"
                                        @isValid="(value) => { isValid.shipping = value }" />
                                </div>
                            </template>
                        </Accordion>
                    </template>

                </div>
            </div>
        </template>
        <template v-else>
            <p><i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!</p>
        </template>
    </div>
</template>

<script>
import Loader from '@components/global/Loader'
import SearchInput from '@components/global/SearchInput'
import Exchange from '@components/registrations/update/classes/Exchange'
import Portions from '@components/registrations/update/portions/Portions'
import Selections from '@components/registrations/update/selections/Selections'
import Instructions from '@components/registrations/update/instructions/Instructions'
import ShippingAddress from '@components/registrations/update/shipping/ShippingAddress'
import ContactInfo from '@components/registrations/update/contact/ContactInfo'
import Checkbox from '@components/global/forms/Checkbox'
import Accordion from '@components/global/Accordion'
import PaymentsListView from '@components/registrations/update/payments/ListView'
import currency from '@shared/utils/currency'
import { DateTime } from "luxon";

export default {
    name: "Edit",

    components: {
        Accordion,
        Loader,
        SearchInput,
        Exchange,
        Portions,
        Selections,
        Instructions,
        ShippingAddress,
        ContactInfo,
        Checkbox,
        PaymentsListView,
    },

    data() {
        return {
            loading: false,
            saving: false,
            success: false,
            error: null,
            sendEmails: false,
            sendInvoice: true,
            totals: null,
            loadingTotals: false,
            override: false,
            overrideRefund: false,
            selectedPayment: null,
            isValid: {
                shipping: false,
                selections: false,
            },
        }
    },

    computed: {
        isRefund() {
            return currency(this.selectedTotals.amount_due).lessThan(currency(0))
        },
        isInvoice() {
            return currency(this.selectedTotals.amount_due).greaterThan(currency(0.5))
        },
        refundAmount() {
            return currency(0).subtract(currency(this.selectedTotals.amount_due)).toFormat()
        },
        toEmail() {
            return this.registration?.guest?.email
        },
        bccEmail() {
            return this.classEvent?.location?.lead?.email
        },
        updateData() {
            return this.$app.store.state.registration.update.data
        },
        registration() {
            return this.$app.store.state.registration.original
        },
        order() {
            return this.$app.store.state.registration.original.order
        },
        classEvent() {
            return this.$app.store.state.registration.update.data.classEvent
        },
        classEventId() {
            return this.$app.store.state.registration.update.data.classEvent?.id
        },
        classEventChanged() {
            return this.classEventId !== this.registration.class_event_id
        },
        portion() {
            return this.$app.store.state.registration.update.data.portion
        },
        selections() {
            return this.$app.store.state.registration.update.data.selections
        },
        meals() {
            return this.$app.store.state.registration.update.data.classEvent.menu.meals
        },
        contactInfo() {
            return this.$app.store.state.registration.update.data.contact
        },
        shippingAddress() {
            return this.$app.store.state.registration.update.data.shipping
        },
        instructions() {
            return this.$app.store.state.registration.update.data.instructions
        },
        classReview() {
            return this.classEvent.completed || !this.classEvent.active || !this.classEvent.enabled || this.classEvent.canceled || DateTime.now() < DateTime.fromISO(this.classEvent?.registration_ends)
        },
        selectionReview() {
            return this.$app.store.state.registration.update.review.selections
        },
        portionReview() {
            return this.$app.store.state.registration.update.review.portion
        },
        shippingReview() {
            return this.$app.store.state.registration.update.review.shipping
        },
        variationId() {
            return this.$app.store.state.registration.update.data.variation?.id
        },
        selectedTotals() {
            if (this.totals?.order) {
                return {
                    subtotal: this.totals.order.subtotal,
                    discounts: this.totals.order.discounts,
                    taxes: this.totals.order.taxes,
                    fees: this.totals.order.fees,
                    total: this.totals.order.total,
                    amount_due: this.totals.order.amount_due
                }
            }
            return false
        },
        canUpdateRegistration() {
            // exchanged - cannot edit
            if (this.registration.status_id === 3) {
                return false
            }
            // delivery - needs valid shipping address
            if ((this.classEvent.fulfillment.id === 3 || this.classEvent.fulfillment.id === 4) && this.isValid.shipping === false) {
                return false
            }
            if (this.classReview && !this.override) {
                return false
            }
            if (this.isValid.selections === false) {
                return false
            }
            if (this.isRefund && !this.selectedPayment && !this.overrideRefund) {
                return false
            }
            return true
        },
    },

    watch: {
        variationId: {
            handler(newValue, oldValue) {
                if (newValue && this.classEvent?.id) this.getTotals()
            },
        },
        classEventId: {
            handler(newValue, oldValue) {
                if (newValue && this.variationId) this.getTotals()
            },
        },
        selections: {
            handler(newValue, oldValue) {
                if (this.classEvent?.id) this.getTotals()
            },
            deep: true,
        },
    },

    methods: {
        handlePaymentSelected(event) {
            this.selectedPayment = event
        },
        handleOverride(event) {
            if (event?.override) this.override = true
        },
        setUpdateData(registration) {
            let params = {
                classEvent: registration.class,
                portion: { size: registration.portion_size, quantity: registration.portion_quantity },
                variation: registration.portion_variation,
                selections: registration.selections,
                instructions: registration.instructions,
                prepInstructions: registration.prep_instructions,
                shipping: registration.order?.shipping_address || null,
                contact: registration.order?.customer || null,
            }

            this.$app.store.commit('registration/setUpdateData', params)
        },
        getRegistration() {
            let params = {
                relations: [
                    'guests',
                    'order.contacts',
                    'order.orderDiscounts.promotion',
                    'order.orderDiscounts.promotionCode',
                    'order.shippingAddress',
                    'order.payments',
                    'order.payments.service',
                    'order.payments.type',
                    'order.payments.status',
                    'class.menu',
                    'class.menu.type',
                    'class.menu.meals.meal',
                    'class.fulfillment',
                    'class.portionVariations',
                    'class.portionVariations.portionSize',
                    'class.portionVariations.portionQuantity',
                    'class.reservationSize',
                    'portionVariation'
                ].join('|')
            }

            this.loading = true
            return this.$app.api.registrations.getRegistration(this.$route.params.id, params)
                .then((response) => {
                    this.$app.store.commit('registration/setRegistration', response.data)
                    this.setUpdateData(response.data)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateRegistration() {
            let params = {}
            params.class_event_id = this.$app.store.state.registration.update.data.classEvent.id
            params.variation_id = this.$app.store.state.registration.update.data.variation.id
            params.selections = this.$app.store.state.registration.update.data.selections
            params.instructions = this.$app.store.state.registration.update.data.instructions
            params.prep_instructions = this.$app.store.state.registration.update.data.prepInstructions
            params.override = this.override
            params.override_refund = this.overrideRefund
            params.send_emails = this.sendEmails
            params.send_invoice = this.sendInvoice
            if (this.selectedPayment?.id) params.payment_id = this.selectedPayment.id
            if (this.classEvent.fulfillment.key === 'delivery' || this.classEvent.fulfillment.key === 'shipping') {
                params.shipping = { ...this.shippingAddress, administrative_area: this.shippingAddress.administrative_area.value }
            }

            this.error = null
            this.saving = true
            this.$app.api.registrations.updateRegistration(this.$route.params.id, params)
                .then((response) => {
                    this.success = true
                    this.$app.store.commit('registration/reset')
                    setTimeout(() => {
                        this.$router.push(`/registrations/${this.$route.params.id}`)
                    }, 500)
                })
                .catch((error) => {
                    console.error(error)
                    this.error = error.response?.data
                })
                .finally(() => {
                    this.saving = false
                })
        },
        getTotals() {
            let params = {}
            params.class_event_id = this.$app.store.state.registration.update.data.classEvent.id
            params.variation_id = this.$app.store.state.registration.update.data.variation.id
            params.selections = this.$app.store.state.registration.update.data.selections
            params.instructions = this.$app.store.state.registration.update.data.instructions
            params.send_emails = this.sendEmails

            this.loadingTotals = true
            return this.$app.api.registrations.getTotals(this.$route.params.id, params)
                .then((response) => {
                    this.totals = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loadingTotals = false
                })
        },
        cancel() {
            this.$app.store.commit('registration/reset')
            this.$router.push(`/registrations/${this.$route.params.id}`)
        },
    },

    created() {
        this.$app.store.commit('registration/reset')
        this.getRegistration()
    },
}
</script>

<style lang="scss" scoped></style>
